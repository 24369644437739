import React, { Fragment } from 'react';
import Navbar from './components/Navbar';
import BgImg from './components/BgImg';
import BlueCarousel from './components/BlueCarousel';
import Question from './components/Question';
import Responsive from './components/Responsive';
import Footer from './components/Footer/Footer';
import Content from './components/Content';
import Testimonials from './components/Testimonials';
import Benefits from './components/Benefits';
import { TranslateProvider } from './components/translateRender';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Contact from './pages/Contact';
import Equipment from './pages/Equipment';
import Pricing from './pages/Pricing';
import Kool11 from './pages/Equipment/Kool11';
import P1000 from './pages/Equipment/P1000';
import Nav from 'reactstrap/es/Nav';

class App extends React.Component {
  render() {
    return (
      <Router>
        <TranslateProvider
          data={{
            Navbar1Item1: {
              en: 'Sunmi',
              zh_hk: '商米系列',
              zh_cn: '商米系列'
            },
            Navbar1Item2: {
              en: 'P1000',
              zh_hk: 'P1000',
              zh_cn: 'P1000'
            },
            Navbar3Item1: {
              en: 'Registration',
              zh_hk: '註冊',
              zh_cn: '注册'
            },
            Navbar3Item2: {
              en: 'Blog',
              zh_hk: '博客',
              zh_cn: '博客'
            },
            Banner1p: {
              en: 'The Simplest Way to Get Paid',
              zh_hk: '最簡單的付款方式',
              zh_cn: '最简单的付款方式'
            },
            Banner1Header: {
              en: 'Secure Payment Processing Solution',
              zh_hk: '安全付款處理解決方案',
              zh_cn: '安全付款处理解决方案'
            },
            Banner1Button1: {
              en: 'Get Started',
              zh_hk: '立即開始',
              zh_cn: '立即开始'
            },
            Banner1Button2: {
              en: 'Free Quote',
              zh_hk: '免費報價',
              zh_cn: '免费报价'
            },
            Benefit1Header: {
              en: 'Payment Processing Solutions',
              zh_hk: '付款處理解決方案',
              zh_cn: '付款处理解决方案'
            },
            Benefit1Content: {
              en:
                'Accept payments in the store on the road and online easily with our payment solution.',
              zh_hk: '通過我們的商店，可以輕鬆地在路上和網上接受商店付款...',
              zh_cn: '通过我们的商店，可以轻松地在路上和网上接受商店付款...'
            },
            Benefit2Header: {
              en: 'Pricing Plans',
              zh_hk: '定價方案',
              zh_cn: '定价方案'
            },
            Benefit2Content: {
              en:
                'With no hidden fees,we created a transparent sales model based on honesty.',
              zh_hk:
                '在沒有任何隱性費用的情況下，我們基於誠實創建了透明的銷售模型。',
              zh_cn:
                '在没有任何隐性费用的情况下，我们基于诚实创建了透明的销售模型。'
            },
            Benefit3Header: {
              en: 'Useful Resources',
              zh_hk: '有用的資源',
              zh_cn: '有用的资源'
            },
            Benefit3Content: {
              en:
                'Discover a collection of tools & tutorials that will help you to customize payment processing...',
              zh_hk: '發現一系列工具和教程，可幫助您自定義付款處理...',
              zh_cn: '发现一系列工具和教程，可帮助您自定义付款处理...'
            },
            Benefit4Header: {
              en: 'UNOPAY Services',
              zh_hk: 'UNOPAY服務',
              zh_cn: 'UNOPAY服务'
            },
            Benefit4Content: {
              en:
                'You get your own dedicated account manager in addition to custom support.',
              zh_hk: '除了提供24/7全天候支持，您還可以擁有自己的專屬客戶經理',
              zh_cn: '除了提供24/7全天候支持，您还可以拥有自己的专属客户经理'
            },
            QuestionTitle: {
              en: 'UNOPAY Advanced Payment Platform',
              zh_hk: 'UNOPAY高級支付平台',
              zh_cn: 'UNOPAY高级支付平台'
            },
            QuestionTitlep: {
              en:
                'We are a leading provider of technology to offer comprehensive merchant solutions. Whether you’re just starting out and need a merchant account with a credit card reader, or you’re expanding fast and need tools to manage your operations, we can help.',
              zh_hk:
                '我們是提供全面商戶解決方案的領先技術提供商。無論您是剛開始並需要使用讀卡器的商戶帳戶，還是正在快速擴展並需要管理您的業務的工具，我們都可以為您提供幫助。',
              zh_cn:
                '我们是提供全面商户解决方案的领先技术提供商。无论您是刚开始并需要使用读卡器的商户帐户，还是正在快速扩展并需要管理您的业务的工具，我们都可以为您提供帮助。'
            },
            Question1Title: {
              en: 'Our Benefits',
              zh_hk: '我們的好處',
              zh_cn: '我们的好处'
            },
            Question1: {
              en: 'Free Payment Gateway',
              zh_hk: '免費付款網關',
              zh_cn: '免费付款网关'
            },
            Question1p: {
              en:
                'UNOPAY includes everything you need to start accepting payments right away while reducing your costs.',
              zh_hk: 'UNOPAY包含您立即開始接受付款同時降低成本所需的一切。',
              zh_cn: 'UNOPAY包含您立即开始接受付款同时降低成本所需的一切。'
            },
            Question2: {
              en: 'Simple Integration',
              zh_hk: '簡單整合',
              zh_cn: '简单整合'
            },
            Question2p: {
              en:
                'We offer a simple plug-and-play and fully customizable integration options to get your website payment-ready.',
              zh_hk:
                '我們提供了一個簡單的即插即用和完全可自定義的集成選項，以使您的網站可以付款。',
              zh_cn:
                '我们提供了一个简单的即插即用和完全可自定义的集成选项，以使您的网站可以付款。'
            },
            Question3: {
              en: 'Real-Time Reporting & Control',
              zh_hk: '實時報告與控制',
              zh_cn: '实时报告与控制'
            },
            Question3p: {
              en:
                'You can easily manage all aspects with one robust tool, view live transactions, charts and statistics.',
              zh_hk:
                '您可以使用一個強大的工具輕鬆管理所有方面，查看實時交易，圖表和統計信息。',
              zh_cn:
                '您可以使用一个强大的工具轻松管理所有方面，查看实时交易，图表和统计信息。'
            },
            Question4: {
              en: 'Professional Branding',
              zh_hk: '專業品牌',
              zh_cn: '专业品牌'
            },
            Question4p: {
              en:
                'With your own merchant account you can easily get your own business name on customer credit card statements.',
              zh_hk:
                '使用您自己的商人帳戶，您可以輕鬆地在客戶信用卡對帳單上獲得自己的公司名稱。',
              zh_cn:
                '使用您自己的商人帐户，您可以轻松地在客户信用卡对帐单上获得自己的公司名称。'
            },
            Banner2Header: {
              en: 'Get the best payment processing plan for your Business',
              zh_hk: '獲得適合您企業的最佳信用卡處理計劃',
              zh_cn: '获得适合您企业的最佳信用卡处理计划'
            },
            Banner2p1: {
              en: 'Retail',
              zh_hk: '零售',
              zh_cn: '零售'
            },
            Banner2p2: {
              en: 'Food & Restaurant',
              zh_hk: '食物和餐廳',
              zh_cn: '食物和餐厅'
            },
            Banner2p3: {
              en: 'Hotels',
              zh_hk: '酒店',
              zh_cn: '酒店'
            },
            Banner2p4: {
              en: 'eCommerce',
              zh_hk: '電子商務',
              zh_cn: '电子商务'
            },
            Banner2p5: {
              en: 'Repair Services',
              zh_hk: '維修服務',
              zh_cn: '维修服务'
            },
            Banner2p6: {
              en: 'Transportations',
              zh_hk: '交通運輸',
              zh_cn: '交通运输'
            },
            Banner2p7: {
              en: 'Health',
              zh_hk: '健身',
              zh_cn: '健身'
            },
            Banner2p8: {
              en: 'Beauty',
              zh_hk: '美容',
              zh_cn: '美容'
            },
            Banner2p9: {
              en: 'Education',
              zh_hk: '教育',
              zh_cn: '教育'
            },
            Banner2p10: {
              en: 'Financial Services',
              zh_hk: '金融服務',
              zh_cn: '金融服务'
            },
            TestimonialsHeader: {
              en: 'Testimonials',
              zh_hk: '感言',
              zh_cn: '感言'
            },
            Testimonials1: {
              en:
                'Because my clients have monthly recurring amounts, I can easily plug them into the Unopay platform, set them up to automatically run the payments and send out receipts, and then reconcile my reports easily each month. That has been really amazing for my business.',
              zh_hk:
                '因為我的客戶有每月的經常性金額，所以我可以輕鬆地將它們放入Unopay平台，將其設置為自動付款並發送收據，然後每月輕鬆地對帳報表。這對我來說真是太神奇了。',
              zh_cn:
                '因为我的客户有每月的经常性金额，所以我可以轻松地将它们放入Unopay平台，将其设置为自动付款并发送收据，然后每月轻松地对帐报表。这对我来说真是太神奇了。'
            },
            Testimonials1p: {
              en: 'Patrick Johnson',
              zh_hk: '帕特里克·約翰遜',
              zh_cn: '帕特里克·约翰逊'
            },
            Testimonials1m: {
              en: 'Sales Manager​​​​​​​',
              zh_hk: '銷售經理',
              zh_cn: '销售经理​​​​​​​'
            },
            Testimonials2: {
              en:
                'I can’t believe how much $ we save every month, we are averaging 1.4% per month. We got ripped off for years by other merchant processors, but thanks to payment depot that is a thing of the past!',
              zh_hk:
                '我無法相信我們每個月節省了多少錢，我們平均每個月節省1.4％。多年以來，我們都被其他商家處理程序所剝奪，但已成為過去！',
              zh_cn:
                '我无法相信我们每个月节省了多少钱，我们平均每个月节省1.4％。多年以来，我们都被其他商家处理程序所剥夺，但已成为过去！'
            },
            Testimonials2p: {
              en: 'David Noon',
              zh_hk: '大衛·努恩',
              zh_cn: '大卫·努恩'
            },
            Testimonials2m: {
              en: 'Restaurant owner​​​​​​​',
              zh_hk: '餐廳老闆',
              zh_cn: '餐厅老板​​​​​​​'
            },
            Content: {
              en: 'Call for a Free Quote:',
              zh_hk: '致電免費報價:',
              zh_cn: '致电免费报价:'
            },
            Footer1: {
              en:
                'We are a leading provider of technology to offer comprehensive merchant solutions. Whether you’re just starting out and need a merchant account with a credit card reader, or you’re expanding fast and need tools to manage your operations, we can help.',
              zh_hk:
                '我們是提供全面商戶解決方案的領先技術提供商。無論您是剛開始並需要使用讀卡器的商戶帳戶，還是正在快速擴展並需要管理您的業務的工具，我們都可以為您提供幫助。',
              zh_cn:
                '我们是提供全面商户解决方案的领先技术提供商。无论您是刚开始并需要使用读卡器的商户帐户，还是正在快速扩展并需要管理您的业务的工具，我们都可以为您提供帮助。'
            },
            Footer2Header: {
              en: 'Quick Links:',
              zh_hk: '快速鏈接：',
              zh_cn: '快速链接：'
            },
            Footer2p1: {
              en: 'How it Works',
              zh_hk: '怎麼運行的',
              zh_cn: '怎么运行的'
            },
            Footer2p2: {
              en: 'Equipment',
              zh_hk: '設備',
              zh_cn: '设备'
            },
            Footer2p3: {
              en: 'Industry',
              zh_hk: '行業',
              zh_cn: '行业'
            },
            Footer2p4: {
              en: 'Developers',
              zh_hk: '開發者們',
              zh_cn: '开发者们'
            },
            Footer2p5: {
              en: 'Pricing',
              zh_hk: '價錢',
              zh_cn: '价钱'
            },
            Footer2p6: {
              en: 'About',
              zh_hk: '關於',
              zh_cn: '关于'
            },
            Footer2p7: {
              en: 'Contacts',
              zh_hk: '聯絡人',
              zh_cn: '联络人'
            },
            Footer3Header: {
              en: 'Contacts',
              zh_hk: '聯絡方法',
              zh_cn: '联络方法'
            },
            Footer3p1: {
              en: 'Call us now',
              zh_hk: '馬上聯絡我們',
              zh_cn: '马上联络我们'
            },
            Footer3p2: {
              en: 'Email us',
              zh_hk: '電郵我們',
              zh_cn: '电邮我们'
            },
            FooterLast: {
              en:
                'Copyright © 2020 All Rights Reserved. Designed by Unopay.asia',
              zh_hk: '版權所有©2020保留所有權利。由Unopay.asia設計',
              zh_cn: '版权所有©2020保留所有权利。由Unopay.asia设计'
            },
            //Equipment Page Translate
            EquipmentBannerHeader: {
              en: 'Payment\n' + 'Processing Equipment',
              zh_hk: '付款\n' + '處理設備',
              zh_cn: '付款\n' + '处理设备'
            },
            EquipmentBanner1p: {
              en: 'Technology Solutions',
              zh_hk: '技術解決方案',
              zh_cn: '技术解决方案'
            },
            //Kool11 Page Translate
            Kool11BannerHeader: {
              en: 'Kool11',
              zh_hk: 'Kool11',
              zh_cn: 'Kool11'
            },
            Kool11Banner1p: {
              en: 'Equipment Solutions',
              zh_hk: '技術解決方案',
              zh_cn: '技术解决方案'
            },
            Kool11Detail: {
              en: 'Operated with cloud-based technologies and customized Android system, the smart cashier runs on a unified system with multiple payment points and data visualization tools. The device enables various industry-specific solutions and a wide range of payment methods. An easy and convenient way to run your business.Certification awarded from international financial institutions such as UnionPay, VISA and MasterCard.',
              zh_hk: '智能收銀 多種支付分式雲後台 定制安卓系統 通過銀聯、VISA等國際金融機構認證。 雲後台數據可視化，多種行業解決方案，多種支付方式，輕鬆便捷，生意無憂。',
              zh_cn:'智能收银 多种支付分式云后台 定制安卓系统 通过银联、VISA等国际金融机构认证。云后台数据可视化，多种行业解决方案，多种支付方式，轻松便捷，生意无忧。'
            }
            ,
            Kool11IntroductionHeader: {
              en: 'Kool11 Intelligent commercial terminal',
              zh_hk: 'Kool10智能商用終端',
              zh_cn: 'Kool10智能商业终端'
            },
            //P1000 Page Translate
            P1000BannerHeader: {
              en: 'P1000',
              zh_hk: 'P1000',
              zh_cn: 'P1000'
            },
            P1000Banner1p: {
              en: 'Equipment Solutions',
              zh_hk: '技術解決方案',
              zh_cn: '技术解决方案'
            },
            P1000Detail:{
              en: 'With high-definition full touch screen runs on Android operating system, the smart terminal accepts multiple payment methods such as bank cards, WeChat Pay, Alipay and others. Supported with O2O solution, mobile application, card coupon verification, industry value-added tools.',
              zh_hk:'高清全觸屏，支持多種支付方式 銀行卡受理功能，微信、支付寶支付，O2O線下、APP應用、卡券核銷、行業增值應用、安卓操作系統',
              zh_cn:'高清全触屏，支持多种支付方式 银行卡受理功能，微信、支付宝支付，O2O线下、APP应用、卡券核销、行业增值应用、安卓操作系统。'
            }
            ,
            P1000IntroductionHeader: {
              en: 'P1000 Smart handheld terminal',
              zh_hk: 'P1000智能手持終端',
              zh_cn: 'P1000智能手持終端'
            },
            //Pricing Page Translate
            PricingBannerHeader: {
              en: 'Pricing Plans',
              zh_hk: '定價方案',
              zh_cn: '定价方案'
            },
            PricingBanner1p: {
              en: 'Choose the Best Option for Your Business',
              zh_hk: '選擇適合您業務的最佳選擇',
              zh_cn: '选择适合您业务的最佳选择'
            },
            PlanHeader: {
              en: 'Pricing Solutions',
              zh_hk: '定價方案',
              zh_cn: '定价方案'
            },
            plan_info_header: {
              en: 'Alipay/WeChat Pay',
              zh_hk: '支付寶/微信',
              zh_cn: '支付宝/微信'
            },
            plan_info_head: {
              en: 'for cross-boarder business',
              zh_hk: '適用於跨界業務',
              zh_cn: '适用于跨界业务'
            },
            plan_info_header2: {
              en: 'Visa/Master/Union Pay',
              zh_hk: '維薩卡/萬事達卡/銀聯卡',
              zh_cn: '维萨卡/万事达卡/银联卡'
            },
            plan_info_head2: {
              en: 'for international business',
              zh_hk: '適用於國際業務',
              zh_cn: '适用于国际业务'
            },
            plan_info_header3: {
              en: 'Octopus',
              zh_hk: '八達通',
              zh_cn: '八达通'
            },
            plan_info_head3: {
              en: 'for local business',
              zh_hk: '適用於本地業務',
              zh_cn: '适用于本地业务'
            },
            plan_info_month: {
              en: '/month',
              zh_hk: '/月',
              zh_cn: '/月'
            },
            layout_btn3: {
              en: 'Get Started',
              zh_hk: '立即開始',
              zh_cn: '立即开始'
            },
            //Contact Page Translates
            ContactBannerHeader: {
              en: 'Contact Information',
              zh_hk: '聯繫信息',
              zh_cn: '联系信息'
            },
            ContactBanner1p: {
              en: 'How To Find Us',
              zh_hk: '如何找到我們',
              zh_cn: '如何找到我们'
            },
            ContactDetails: {
              en: 'Contact Details',
              zh_hk: '聯繫方式',
              zh_cn: '联系方式'
            },
            AddressHeader: {
              en: 'Address:',
              zh_hk: '地址:',
              zh_cn: '地址:'
            },
            AddressDetail: {
              en:
                'Room 207, Fabrico Industrial Building 78-81 Kwai Cheong Road, Kwai Hing',
              zh_hk:
                '葵興 葵昌路78-81號 富都工業大廈207室',
              zh_cn:
                '葵兴 葵昌路78-81号 富都工业大厦207室'
            },
            PhoneHeader: {
              en: 'Phone:',
              zh_hk: '電話:',
              zh_cn: '电话:'
            },
            EmailsHeader: {
              en: 'Emails:',
              zh_hk: '電郵:',
              zh_cn: '电邮:'
            },
            PlaceHolderName: {
              en: 'Name *',
              zh_hk: '姓名 *',
              zh_cn: '姓名 *'
            },
            PlaceHolderEmail: {
              en: 'Email *',
              zh_hk: '電郵 *',
              zh_cn: '电邮 *'
            },
            PlaceHolderMessage: {
              en: 'Message *',
              zh_hk: '信息 *',
              zh_cn: '信息 *'
            },
            question_btn: {
              en: 'Send Message',
              zh_hk: '發送信息',
              zh_cn: '发送信息'
            }
          }}
        >
          <div
            className='h-100'
            style={{
              overflow: 'auto'
            }}
          >
            <Navbar />
            <Switch>
              <Route path={'/contacts'}>
                <Contact />
              </Route>
              <Route path={'/equipment'}>
                <Equipment />
              </Route>
              <Route path={'/kool11'}>
                <Kool11 />
              </Route>
              <Route path={'/p1000'}>
                <P1000 />
              </Route>
              <Route path={'/pricing'}>
                <Pricing />
              </Route>
              <Route path={'/'}>
                <BgImg />
                <BlueCarousel />
                <Benefits />
                <Question />
                <Responsive />
                <Testimonials />
                <Content />
              </Route>
            </Switch>
            <Footer />
          </div>
        </TranslateProvider>
      </Router>
    );
  }
}

export default App;
